import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "add-cal" };
const _hoisted_2 = { class: "f-inter menu-label" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_CalendarAltSvg = _resolveComponent("CalendarAltSvg");
    const _component_MenuDropdown = _resolveComponent("MenuDropdown");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_MenuDropdown, {
            items: _ctx.calendarItems,
            "start-open": _ctx.startOpen,
            "onMenuItem:click": _cache[0] || (_cache[0] = ($event) => (_ctx.onCalendarClickHandler($event)))
        }, {
            toggle: _withCtx(() => [
                _renderSlot(_ctx.$slots, "toggle", {}, () => [
                    _createVNode(_component_CalendarAltSvg, { class: "calendar-icon" }),
                    _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.t.addLabel), 1)
                ], true)
            ]),
            _: 3
        }, 8, ["items", "start-open"])
    ]));
}
