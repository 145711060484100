import { defineComponent } from 'vue';
import { validator } from "@ts/Util/i18n";
import { v4 as uuid } from 'uuid';
import MenuDropdown from "@components/Menus/MenuDropdown.vue";
import CalendarAltSvg from '@bx-icons/regular/bx-calendar-alt.svg';
const localT = {
    addLabel: 'Add',
    calendarGoogle: 'Google',
    calendarYahoo: 'Yahoo',
    calendarApple: 'Apple',
    calendarOutlook: 'Outlook',
};
export default defineComponent({
    name: 'CalendarsDropdown',
    components: {
        MenuDropdown,
        CalendarAltSvg,
    },
    props: {
        eventName: {
            type: String,
            required: true,
        },
        /**
         * Event's body information
         */
        eventDescription: {
            type: String,
            required: true,
        },
        /**
         * Event start date
         */
        startDate: {
            type: Date,
            required: true,
        },
        /**
         * Event end date
         */
        endDate: {
            type: Date,
            required: true,
        },
        /**
         * Timezone the event will take place
         */
        timezone: {
            type: String,
            default: ''
        },
        /**
         * Event's location
         */
        location: {
            type: String,
            default: '',
        },
        t: {
            type: Object,
            default: () => localT,
            validator: validator(localT)
        },
        startOpen: {
            type: Boolean,
            default: false,
        },
    },
    emits: [
        'close',
    ],
    setup(props) {
        const calendarItems = [
            {
                id: 'google',
                label: props.t.calendarGoogle,
            },
            {
                id: 'yahoo',
                label: props.t.calendarYahoo,
            },
            {
                id: 'apple',
                label: props.t.calendarApple,
            },
            {
                id: 'outlook',
                label: props.t.calendarOutlook,
            },
        ];
        // variable to store in-memory calendar file generated
        let icsFile = null;
        /**
         * Sanitize the milliseconds from ISO generated string
         */
        const sanitizeDate = (date) => {
            return date ? date.toISOString().replace(/-|:|\.\d+/g, '') : null;
        };
        const onCalendarClickHandler = (ev) => {
            // map the menu item to what it should do for non-link items
            const handlers = {
                'google': () => {
                    const url = generateGoogleCalendar();
                    window.open(url, '_blank')?.focus();
                },
                'outlook': () => {
                    const url = generateOutlookCalendar();
                    window.open(url, '_blank')?.focus();
                },
                'yahoo': () => {
                    const url = generateYahooCalendar();
                    window.open(url, '_blank')?.focus();
                },
                'apple': () => {
                    const fileBlob = makeIcsFile(sanitizeDate(props.startDate) ?? '', sanitizeDate(props.endDate) ?? '', props.eventName, props.eventDescription, props.location);
                    const element = document.createElement('a');
                    element.setAttribute('href', fileBlob);
                    element.setAttribute('download', 'event.ics');
                    element.style.display = 'none';
                    document.body.appendChild(element);
                    element.click();
                    document.body.removeChild(element);
                },
            };
            if (ev.itemId in handlers) {
                handlers[ev.itemId]();
            }
            else {
                console.warn('Menu item not registered', ev.itemId);
            }
        };
        const generateGoogleCalendar = () => {
            // base url
            const eventURL = new URL('https://calendar.google.com/calendar/render');
            eventURL.searchParams.append('action', 'TEMPLATE');
            eventURL.searchParams.append('title', props.eventName);
            eventURL.searchParams.append('text', props.eventName);
            eventURL.searchParams.append('details', props.eventDescription);
            eventURL.searchParams.append('dates', (`${sanitizeDate(props.startDate)}/${sanitizeDate(props.endDate)}`));
            if (props.timezone) {
                eventURL.searchParams.append('ctz', props.timezone);
            }
            if (props.location) {
                eventURL.searchParams.append('location', props.location);
            }
            return eventURL;
        };
        const generateOutlookCalendar = () => {
            const eventURL = new URL('https://outlook.live.com/calendar/0/deeplink/compose');
            eventURL.searchParams.append('path', '/calendar/action/compose');
            eventURL.searchParams.append('rru', 'addevent');
            eventURL.searchParams.append('subject', props.eventName);
            eventURL.searchParams.append('body', props.eventDescription);
            eventURL.searchParams.append('startdt', sanitizeDate(props.startDate) ?? '');
            eventURL.searchParams.append('enddt', sanitizeDate(props.endDate) ?? '');
            if (props.location) {
                eventURL.searchParams.append('location', props.location);
            }
            return eventURL;
        };
        const generateYahooCalendar = () => {
            const eventURL = new URL('https://calendar.yahoo.com');
            eventURL.searchParams.append('v', '60');
            eventURL.searchParams.append('TITLE', props.eventName);
            eventURL.searchParams.append('DESC', props.eventDescription);
            eventURL.searchParams.append('ST', sanitizeDate(props.startDate) ?? '');
            eventURL.searchParams.append('ET', sanitizeDate(props.endDate) ?? '');
            if (props.location) {
                eventURL.searchParams.append('in_loc', props.location);
            }
            return eventURL;
        };
        const makeIcsFile = (startDate, endDate, summary, description, location) => {
            let desc = description.replace(/.{65}/g, '$&' + '\r\n '); // making sure it does not exceed 75 characters per line
            const lines = [];
            lines.push('BEGIN:VCALENDAR');
            lines.push('VERSION:2.0');
            lines.push('PRODID:-//zumba.com//Class Calendar 1.0//EN');
            lines.push('CALSCALE:GREGORIAN');
            lines.push('METHOD:PUBLISH');
            lines.push('BEGIN:VEVENT');
            lines.push(`UID:${uuid()}`);
            lines.push('SEQUENCE:0');
            lines.push('STATUS:CONFIRMED');
            lines.push(`DTSTART:${startDate}`);
            lines.push(`DTEND:${endDate}`);
            lines.push(`SUMMARY:${summary}`);
            lines.push(`DESCRIPTION:${desc}`);
            lines.push(`LOCATION:${location ?? ''}`);
            lines.push('END:VEVENT');
            lines.push('END:VCALENDAR');
            let icsData = lines.join("\n");
            const icsFileBlob = new File([icsData], 'calendar-event.ics', { type: "text/calendar" });
            // If we are replacing a previously generated file we need to
            // manually revoke the object URL to avoid memory leaks.
            if (icsFile !== null) {
                window.URL.revokeObjectURL(icsFile);
            }
            icsFile = window.URL.createObjectURL(icsFileBlob);
            return icsFile;
        };
        return {
            calendarItems,
            onCalendarClickHandler,
        };
    },
});
