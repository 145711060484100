import { defineComponent, ref, watch } from 'vue';
import ThreeDotsVerticalIcon from '@bx-icons/regular/bx-dots-vertical-rounded.svg';
/**
 * This uses 3 dots for the trigger, and a dropdown for both mobile and desktop.
 *
 * Tip: Check out the MenuCombo if you need a mobile drawer when on mobile.  This one only does dropdown.
 */
export default defineComponent({
    name: 'MenuDropdown',
    components: {
        ThreeDotsVerticalIcon,
    },
    props: {
        items: {
            type: Array,
            default: () => [],
        },
        type: {
            type: String,
            default: 'default',
            validator: (val) => ['default', 'wide'].includes(val)
        },
        startOpen: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['menu-item:click', 'menu:opened', 'menu:closed'],
    setup(props, context) {
        const menu = ref(null);
        const isOpen = ref(false);
        /**
         * Menu open/close main controls...
         */
        const onToggleClick = () => {
            if (isOpen.value) {
                isOpen.value = false;
            }
            else {
                isOpen.value = true;
                menu.value?.focus();
            }
        };
        if (props.startOpen) {
            onToggleClick();
        }
        const onBlur = (event) => {
            if (!isOpen.value) {
                // already closed
                return;
            }
            // relatedTarget is the clicked element - see if it is just changing focus to another part of the menu
            const relatedNode = event.relatedTarget;
            if (relatedNode && menu.value?.contains(relatedNode)) {
                // new focussed element is a child of the menu, not external focus
                return;
            }
            // Blur was because of outside focus, close the menu
            isOpen.value = false;
        };
        /**
         * Menu item controls...
         */
        const itemClass = (item) => {
            const classes = {
                'item-link': true,
                disabled: item.disabled,
                destructive: item.destructive,
                divider: item.divider,
            };
            if (props.type === 'wide') {
                classes['p-medium'] = true;
            }
            return classes;
        };
        const onItemClick = (event, item) => {
            if (item.disabled) {
                // special case, it should not do anything if disabled
                event.preventDefault();
                return;
            }
            // Note that a listener can use event.preventDefault() to stop the menu from closing after
            context.emit('menu-item:click', {
                itemId: item.id || null,
                item,
                event
            });
            if (!item.href) {
                if (!event.defaultPrevented) {
                    // after clicking if no events prevented default, close the menu
                    isOpen.value = false;
                }
                // keep it from navigating to # - we use # so keyboard navigation works
                event.preventDefault();
            }
        };
        watch(isOpen, (isOpen, oldState) => {
            if (isOpen !== oldState) {
                context.emit(`menu:${isOpen ? 'opened' : 'closed'}`);
            }
        });
        return {
            menu,
            onToggleClick,
            onItemClick,
            onBlur,
            isOpen,
            itemClass,
        };
    },
});
