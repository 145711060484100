import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx } from "vue";
const _hoisted_1 = { class: "menu-dots" };
const _hoisted_2 = {
    key: 0,
    class: "menu-contents"
};
const _hoisted_3 = { class: "ul" };
const _hoisted_4 = ["href", "target", "onClick"];
const _hoisted_5 = { class: "f-inter item-label" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_three_dots_vertical_icon = _resolveComponent("three-dots-vertical-icon");
    return (_openBlock(), _createElementBlock("div", null, [
        _createElementVNode("div", {
            ref: "menu",
            class: _normalizeClass(["menu-wrapper", _ctx.type]),
            tabindex: "-1",
            onBlur: _cache[2] || (_cache[2] = ($event) => (_ctx.onBlur($event)))
        }, [
            _createElementVNode("a", {
                href: "javascript:void(0)",
                onClick: _cache[0] || (_cache[0] = _withModifiers(
                //@ts-ignore
                (...args) => (_ctx.onToggleClick && _ctx.onToggleClick(...args)), ["prevent"]))
            }, [
                _renderSlot(_ctx.$slots, "toggle", {}, () => [
                    _createElementVNode("span", _hoisted_1, [
                        _createVNode(_component_three_dots_vertical_icon)
                    ])
                ], true)
            ]),
            _createVNode(_Transition, { name: "menu-transition" }, {
                default: _withCtx(() => [
                    (_ctx.isOpen)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                            _createElementVNode("ul", _hoisted_3, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, index) => {
                                    return (_openBlock(), _createElementBlock("li", {
                                        key: index,
                                        class: "li",
                                        role: "option"
                                    }, [
                                        _createElementVNode("a", {
                                            href: item.href || '#',
                                            target: item.target || null,
                                            class: _normalizeClass(_ctx.itemClass(item)),
                                            onClick: ($event) => (_ctx.onItemClick($event, item)),
                                            onBlur: _cache[1] || (_cache[1] = ($event) => (_ctx.onBlur($event)))
                                        }, [
                                            _renderSlot(_ctx.$slots, "menu-item", { item: item }, () => [
                                                _createElementVNode("span", _hoisted_5, _toDisplayString(item.label), 1)
                                            ], true)
                                        ], 42, _hoisted_4)
                                    ]));
                                }), 128))
                            ]),
                            _renderSlot(_ctx.$slots, "after-menu-items", {}, undefined, true)
                        ]))
                        : _createCommentVNode("", true)
                ]),
                _: 3
            })
        ], 34)
    ]));
}
